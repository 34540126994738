import React from 'react';
import '../assets/styles/Header.css';
import logo from '../assets/images/logo.png';


function Header() {
  return (
    <header style={{
      marginBottom: "9vh", /* Espaciado entre los elementos */
  }}>
      <div className="header">
      <img src={logo} alt="Logo" />
        <nav>
          <a href="/">Inicio</a> | <a href="/aboutus">¿Quienes Somos?</a> | <a href="/contacto">Contacto</a> | <a href="/services">Servicios</a> | <a href="/herramientas">Herramientas</a>
        </nav>
      </div>
    </header>
  );
}

export default Header;
