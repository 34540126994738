import React from 'react';
import '../assets/styles/ContactButtom.css';

function ContactButtom() {
    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            marginBottom: "6vh", /* Espaciado entre los elementos */
        }}>
            <div style={{ position: "relative" }}>
                <div className="romB"></div>
                <button
                    className="custom-button"
                    style={{
                        width: "400px",
                        height: "60px"
                    }}
                    onClick={() => window.location.href = "/contacto"}>
                    <p>Contáctenos para una consulta gratuita</p>
                </button>
                <div className="romA"></div>
            </div>
        </div>
    );
}

export default ContactButtom;
